<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <PurchaseItem
        v-for="purchase in getPurchases"
        :key="purchase._id"
        :purchase="purchase"
      />
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PurchaseItem from "./components/PurchaseItem";
export default {
  components: {
    PurchaseItem
  },
  computed: {
    ...mapGetters(["getPurchases"])
  },
  methods: {
    ...mapActions(["listPurchases"])
  },
  created() {
    this.listPurchases();
  }
};
</script>
